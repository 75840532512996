import React from "react"
import Layout from "./../components/layouts/Default"
import Container from "../components/layouts/container/Container"
import lautenschlagerLogo from "../img/webdesigner-kassel-lautenschlager-logo.png"

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Impressum | Döbbel Immobilien"
      desc=""
    >
      <Container className="my-8 md:my-16">
        <div className="max-w-3xl p-5 mx-auto">
          <div className="prose">
            <h1>Impressum</h1>
            <h2>Angaben gemäß § 5 TMG</h2>
            <p>
              Thomas Döbbel
              <br />
              Döbbel Immobilien
              <br />
              Magdeburg-Leipziger Str. 127
              <br />
              39443 Förderstedt
            </p>
            <h2>Kontakt</h2>

            <p>
              Telefon: 015126631216
              <br />
              E-Mail: kontakt@doebbel-immobilien.de
            </p>
            <h2>Zulassung nach §34c GewO durch die Stadt Staßfurt</h2>
            <h2>Umsatzsteuer-ID</h2>
            <p>
              Umsatzsteuer-Identifikationsnummer gemäß § 27 a
              Umsatzsteuergesetz:
              <br />
              80 249 531 795
            </p>
            <h2>Angaben zur Berufshaftpflichtversicherung</h2>
            <p>
              <strong>Name und Sitz des Versicherers:</strong>
              <br />
              Hiscox SA
              <br />
              Niederlassung für Deutschland
              <br />
              Arnulfstraße 31
              <br />
              80636 München
              <br />
              <br />
              Versicherungsnr.: HV.DSC.6700023
            </p>
            <h2>EU-Streitschlichtung</h2>
            <p>
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:{" "}
              <a
                href="https://ec.europa.eu/consumers/odr"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ec.europa.eu/consumers/odr
              </a>
              .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>
            <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
            <p>
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </p>
            <h3>Haftung für Inhalte</h3>{" "}
            <p>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen.
            </p>{" "}
            <p>
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
              Informationen nach den allgemeinen Gesetzen bleiben hiervon
              unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
              Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
              Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>{" "}
            <h3>Haftung für Links</h3>{" "}
            <p>
              Unser Angebot enthält Links zu externen Websites Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar.
            </p>{" "}
            <p>
              Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
              jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
              derartige Links umgehend entfernen.
            </p>{" "}
            <h3>Urheberrecht</h3>{" "}
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet.
            </p>{" "}
            <p>
              Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
              wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
              werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
              trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
              bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Inhalte umgehend
              entfernen.
            </p>
          </div>
        </div>
      </Container>
      <Container>
        <div className="max-w-xl p-2 mx-auto my-16 mt-3 text-center bg-gray-100">
          <p className="mb-1 text-xs">Website erstellt von:</p>
          <a href="https://lautenschlager.de/leistungen/webdesign-kassel/" target="_blank" rel="dofollow noreferrer">
            <img
              src={lautenschlagerLogo}
              title="Webdesigner Kassel - Lautenschlager Marketing & Entwicklung"
              alt="Webdesigner Kassel - Lautenschlager Marketing & Entwicklung"
              className="mx-auto"
            />
            <br />
							Webdesigner Kassel - Lautenschlager Marketing & Entwicklung
						</a>
        </div>
      </Container>
    </Layout>
  )
}
